<template>
  <el-scrollbar>
    <el-menu
      class="sidebar"
      background-color="#2f3447"
      text-color="#ffffff"
      active-text-color="#ffffff"
      :default-active="$route.path"
      router
    >
      <!-- <el-image
        class="logo"
        :src="require('@/assets/img/logo-white.png')"
        fit="contain"
      /> -->
      <div class="title">物联网平台管理端</div>
      <el-menu-item index="/index">
        <i class="el-icon-s-home" />
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="/system">
        <template slot="title">
          <i class="el-icon-s-tools" />
          <span slot="title">系统管理</span>
        </template>
        <el-menu-item index="/system/staff">
          <i class="el-icon-" />
          <span slot="title">员工管理</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="/customer">
        <i class="el-icon-menu" />
        <span slot="title">客户管理</span>
      </el-menu-item>
      <el-submenu index="/deviceops">
        <template slot="title">
          <i class="el-icon-s-opportunity" />
          <span slot="title">产品开发</span>
        </template>
        <el-menu-item index="/product">
          <i class="el-icon-s-" />
          <span slot="title">产品定义</span>
        </el-menu-item>
        <el-menu-item index="/device">
          <i class="el-icon-s-" />
          <span slot="title">设备管理</span>
        </el-menu-item>
        <el-menu-item index="/upgrade">
          <i class="el-icon-" />
          <span slot="title">远程升级</span>
        </el-menu-item>
      </el-submenu>
      <!-- <el-menu-item index="/product">
        <i class="el-icon-s-opportunity" />
        <span slot="title">产品定义</span>
      </el-menu-item>
      <el-menu-item index="/device">
        <svg
          t="1641880839067"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3217"
          width="18"
          height="18"
        >
          <path
            d="M800 736H448a32 32 0 0 0 0 64h352a32 32 0 0 0 0-64zM800 480H448a32 32 0 0 0 0 64h352a32 32 0 0 0 0-64zM800 224H448a32 32 0 0 0 0 64h352a32 32 0 0 0 0-64zM304 736h-64a32 32 0 0 0 0 64h64a32 32 0 0 0 0-64zM304 480h-64a32 32 0 0 0 0 64h64a32 32 0 0 0 0-64zM304 224h-64a32 32 0 0 0 0 64h64a32 32 0 0 0 0-64z"
            p-id="3218"
            fill="#909399"
          />
          <path
            d="M864 96H160a96 96 0 0 0-96 96v640a96 96 0 0 0 96 96h704a96 96 0 0 0 96-96V192a96 96 0 0 0-96-96z m32 736a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32v-160h672a32 32 0 0 0 0-64H128v-192h672a32 32 0 0 0 0-64H128V192a32 32 0 0 1 32-32h704a32 32 0 0 1 32 32v640z"
            p-id="3219"
            fill="#909399"
          />
        </svg>
        <span slot="title">设备管理</span>
      </el-menu-item>
      <el-menu-item index="/upgrade">
        <i class="el-icon-upload" />
        <span slot="title">远程升级</span>
      </el-menu-item> -->
      <el-submenu index="/service">
        <template slot="title">
          <i class="el-icon-s-opportunity" />
          <span slot="title">应用服务</span>
        </template>
        <el-menu-item index="/application">
          <i class="el-icon-" />
          <span slot="title">消息服务</span>
        </el-menu-item>
        <el-submenu index="/asset">
          <template slot="title">
            <i class="el-icon-s-" />
            <span slot="title">耗材服务</span>
          </template>
          <el-menu-item index="/asset/device">
            <i class="el-icon-" />
            <span slot="title">设备列表</span>
          </el-menu-item>
          <el-menu-item index="/asset/model">
            <i class="el-icon-" />
            <span slot="title">耗材型号</span>
          </el-menu-item>
          <el-menu-item index="/asset/asset">
            <i class="el-icon-" />
            <span slot="title">耗材管理</span>
          </el-menu-item>
          <el-menu-item index="/asset/doctor">
            <i class="el-icon-" />
            <span slot="title">医生管理</span>
          </el-menu-item>
        </el-submenu>
      </el-submenu>
      <!-- <el-menu-item index="/application">
        <i class="el-icon-menu" />
        <span slot="title">消息服务</span>
      </el-menu-item>
      <el-submenu index="/asset">
        <template slot="title">
          <i class="el-icon-s-tools" />
          <span slot="title">耗材服务</span>
        </template>
        <el-menu-item index="/asset/device">
          <i class="el-icon-" />
          <span slot="title">设备列表</span>
        </el-menu-item>
        <el-menu-item index="/asset/model">
          <i class="el-icon-" />
          <span slot="title">耗材型号</span>
        </el-menu-item>
        <el-menu-item index="/asset/asset">
          <i class="el-icon-" />
          <span slot="title">耗材管理</span>
        </el-menu-item>
        <el-menu-item index="/asset/doctor">
          <i class="el-icon-" />
          <span slot="title">医生管理</span>
        </el-menu-item>
      </el-submenu> -->
      <div class="edition">5.6 v1</div>
      <div style="height: 32px" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.el-scrollbar {
  height: 100vh;
}

.el-scrollbar >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

.sidebar {
  min-height: 100vh;

  border-right: none;
}

.logo {
  height: 30px;

  margin: 25px auto;
}

.title {
  height: 80px;

  line-height: 80px;
  font-size: 25px;
  color: #ffffff;
}

li {
  text-align: left;
}

.el-menu-item:hover,
.el-submenu >>> .el-submenu__title:hover,
.el-submenu >>> .el-menu-item:hover {
  background: #262a39 !important;
}

.el-menu-item.is-active,
.el-submenu >>> .el-menu-item.is-active {
  background: #4165d7 !important;
}

.el-submenu svg,
.el-menu-item svg {
  margin: 0 8px 0 3px;
}

.is-active svg path {
  fill: #fff;
}

.edition {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  color: #fff;
  line-height: 2em;
}
</style>