<template>
  <div id="app">
    <el-aside v-if="isShow" width="255px">
      <Sidebar />
    </el-aside>

    <el-container>
      <el-header v-if="isShow" height="50px">
        <Navbar />
        <Tabs />
      </el-header>

      <el-main v-loading="loading" :class="isShow ? 'main' : ''">
        <el-scrollbar :class="routeClass">
          <div v-if="!loading">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" />
          </div>
          <div class="signature">
            Copyright © www.uartcloud.com, All Rights Reserved.
            一站式物联网人机交互解决方案
          </div>
        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Sidebar from "./components/common/sidebar/Sidebar";
import Navbar from "./components/common/navbar/Navbar";
import Tabs from "./components/common/tabs/Tabs";

export default {
  components: {
    Sidebar,
    Navbar,
    Tabs,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isShow() {
      return !this.$route.meta.hidden;
    },
    routeClass() {
      if (this.$route.path === "/application") {
        return "application";
      } else if (this.$route.path.startsWith("/device/")) {
        return "deviceOne";
      } else {
        return "";
      }
    },
  },
  created() {
    this.checkBrowser();
  },
  mounted() {
    this.$bus.$emit("updateInfo");
  },
  methods: {
    checkBrowser() {
      var theUA = window.navigator.userAgent.toLowerCase();
      console.log(theUA);
      if (
        (theUA.match(/msie\s\d+/) && theUA.match(/msie\s\d+/)[0]) ||
        (theUA.match(/trident\/?\d+/) && theUA.match(/trident\/?\d+/)[0])
      ) {
        var ieVersion =
          (theUA.match(/msie\s\d+/) &&
            theUA.match(/msie\s\d+/)[0].match(/\d+/)[0]) ||
          (theUA.match(/trident\/?\d+/) &&
            theUA.match(/trident\/?\d+/)[0].match(/\d+/)[0]);
        if (ieVersion < 11) {
          var str = "暂不支持较低版本的IE浏览器";
          var str2 =
            "推荐使用:<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>谷歌</a>、" +
            "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%81%AB%E7%8B%90%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>火狐</a>" +
            "或其他双核极速模式";
          document.writeln(
            "<pre style='text-align:center; height:100%;border:0;position:fixed;top:0;left:0;width:100%;z-index:1234'>" +
              "<h2 style='padding-top:200px;margin:0'><strong>" +
              str +
              "<br/></strong></h2><h2>" +
              str2 +
              "</h2><h2 style='margin:0'><strong>如果你的使用的是双核浏览器,请切换到极速模式访问<br/></strong></h2></pre>"
          );
          document.execCommand("Stop");
        }
      }
    },
  },
};
</script>

<style>
@import "assets/css/base.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303133;
}

#app .el-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

#app .el-header {
  position: fixed;
  top: 0;
  left: 255px;
  right: 0;
  z-index: 1;

  padding: 0;

  background: #ffffff;
  box-shadow: 0px 6px 12px 0px rgba(230, 233, 240, 0.3);
}

#app .el-main {
  padding: 0;

  background: #2f3447;
}
#app .el-main.main {
  margin: 100px 0 0 255px;

  background: #f7f7f7;
}

#app .el-main.main > .el-scrollbar {
  height: calc(100vh - 100px);
}
#app .el-main.main > .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
#app .el-main.main > .el-scrollbar > .el-scrollbar__wrap > .el-scrollbar__view {
  min-width: 700px;
}
#app
  .el-main.main
  > .el-scrollbar.application
  > .el-scrollbar__wrap
  > .el-scrollbar__view {
  min-width: 1100px;
}
#app
  .el-main.main
  > .el-scrollbar.deviceOne
  > .el-scrollbar__wrap
  > .el-scrollbar__view {
  min-width: 1470px;
}

.signature {
  margin: 10px auto;

  font-size: 12px;
  color: #fff;
  line-height: 20px;
}
.main .signature {
  color: #c0c4cc;
}

/* elementUI */
#app .el-avatar {
  background: transparent;
}

#app .el-descriptions {
  user-select: text;
}
#app .el-descriptions-item__label.is-bordered-label {
  width: 100px;

  background: transparent;
}
#app .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 0 0 12px;

  border: none;
}

.el-input-group__append .el-button.is-disabled,
.el-input-group__append .el-button.is-disabled:focus,
.el-input-group__append .el-button.is-disabled:hover {
  background-color: transparent;
}

.el-dialog__wrapper .el-dialog__header {
  border-bottom: 1px solid #f7f7f7;
}
.el-dialog__wrapper .el-dialog__title {
  font-size: 16px;
}
.el-dialog__wrapper .el-dialog__footer div {
  text-align: right;
}

/* el-select */
.el-select-dropdown li {
  height: auto !important;

  line-height: normal !important;
  padding: 7px 20px !important;
}
.el-select-dropdown .label {
  text-overflow: ellipsis;
  overflow: hidden;
}
.el-select-dropdown .value {
  font-size: 12px;
  color: #b4b4b4;
}
.el-select-dropdown .highlighted .value {
  color: #ddd;
}

/* autocomplete */
.autocomplete li {
  line-height: normal !important;
  padding: 7px 20px !important;
}
.autocomplete .label {
  text-overflow: ellipsis;
  overflow: hidden;
}
.autocomplete .value {
  font-size: 12px;
  color: #b4b4b4;
}
.autocomplete .highlighted .value {
  color: #ddd;
}
</style>
