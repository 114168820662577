<template>
  <div class="navbar">
    <div>
      <el-breadcrumb>
        <el-breadcrumb-item
          v-for="item in crumbList"
          :key="item.route"
          :to="item.route"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <el-dropdown trigger="click" @command="handleCommand">
        <div>
          <div>{{ getMyProfile.name }}</div>
          <el-avatar :src="getMyProfile.avatar" :size="32">
            <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
            />
          </el-avatar>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="myProfile">个人中心</el-dropdown-item>
          <el-dropdown-item command="editPW">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getProfile } from "@/network/user";

import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      crumbList: [],
    };
  },
  computed: {
    ...mapGetters(["getMyProfile"]),
  },
  created() {
    this.$bus.$on("updateInfo", () => {
      this.getInfo();
    });
    this.$bus.$on("updateCrumb", (list, isTab = true) => {
      this.crumbList = list;
      let pageName = list[list.length - 1].name;
      document.title =
        "物联网平台管理端 - " + (pageName === "首页" ? "优云物联" : pageName);
      if (isTab) {
        this.$bus.$emit("switchTabs", list[list.length - 1]);
      }
    });
  },
  mounted() {},
  methods: {
    ...mapMutations(["setMyProfile"]),
    getInfo() {
      getProfile()
        .then((res) => {
          if (res.code === 0) {
            this.setMyProfile(res.data);
          } else {
            console.log(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          localStorage.removeItem("token");
          this.$router.replace("/login");
          break;
        default:
          this.$router.push("/" + command);
          break;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;

  height: 100%;

  padding: 0 20px;
}

.navbar > div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
}

.el-breadcrumb__item:last-child >>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child >>> .el-breadcrumb__inner.is-link:hover {
  color: #409eff;
  font-weight: 700;
  cursor: pointer;
}
.el-breadcrumb__item:last-child >>> .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child >>> .el-breadcrumb__inner.is-link {
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #303133;
}

.el-breadcrumb__item >>> .el-breadcrumb__inner {
  padding: 0 10px;
}

.el-dropdown-selfdefine {
  display: flex;
  align-items: center;

  height: 50px;

  cursor: pointer;
}

.el-avatar {
  margin-left: 15px;
}
</style>
