import {
  request
} from "./request";

//登录
export function getToken(form) {
  return request({
    url: '/identify/v1/admin/user/login',
    params: {
      username: form.username,
      password: form.password,
    }
  })
}

//获取用户帐号信息
export function getProfile() {
  return request({
    url: '/uyun/v1/admin/user/getinfo',
  })
}

//修改账户信息
export function editProfile(data) {
  return request({
    url: '/uyun/v1/admin/user/updateinfo',
    data
  })
}

//修改账户密码
export function editPW(params) {
  return request({
    url: '/uyun/v1/admin/user/updatepassword',
    params,
  })
}